<template>
    <div class="Toggle-markup">

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="Toggle-icon-stroke" d="M7.99903 10.7608C9.52376 10.7608 10.7598 9.52473 10.7598 8.00001C10.7598 6.47529 9.52376 5.23926 7.99903 5.23926C6.47431 5.23926 5.23828 6.47529 5.23828 8.00001C5.23828 9.52473 6.47431 10.7608 7.99903 10.7608Z" stroke="white" stroke-width="0.7"/>
            <path class="Toggle-icon" fill-rule="evenodd" clip-rule="evenodd" d="M8.63748 2.05409C8.63748 1.70223 8.35221 1.41699 8.00041 1.41699C7.64855 1.41699 7.36328 1.70223 7.36328 2.05409V3.76085C7.57115 3.72987 7.78395 3.71382 8.00041 3.71382C8.21688 3.71382 8.42961 3.72987 8.63748 3.76083V2.05409ZM8.63748 12.2399C8.42961 12.2709 8.21688 12.2869 8.00041 12.2869C7.78395 12.2869 7.57115 12.2709 7.36328 12.2399V13.9466C7.36328 14.2984 7.64855 14.5837 8.00041 14.5837C8.35221 14.5837 8.63748 14.2984 8.63748 13.9466V12.2399Z" fill="white"/>
            <path class="Toggle-icon" fill-rule="evenodd" clip-rule="evenodd" d="M13.9456 8.63748C14.2974 8.63748 14.5827 8.35221 14.5827 8.00041C14.5827 7.64855 14.2974 7.36328 13.9456 7.36328H12.2388C12.2698 7.57115 12.2859 7.78395 12.2859 8.00041C12.2859 8.21688 12.2698 8.42961 12.2388 8.63748H13.9456ZM3.75975 8.63748C3.72878 8.42961 3.71274 8.21688 3.71274 8.00041C3.71274 7.78395 3.72879 7.57115 3.75976 7.36328H2.05311C1.70126 7.36328 1.41602 7.64855 1.41602 8.00041C1.41602 8.35221 1.70126 8.63748 2.05311 8.63748H3.75975Z" fill="white"/>
            <path class="Toggle-icon" fill-rule="evenodd" clip-rule="evenodd" d="M12.6551 4.2458C12.9039 3.99699 12.9039 3.5936 12.6551 3.3448C12.4063 3.096 12.0029 3.096 11.7541 3.3448L10.5471 4.55172C10.7161 4.67681 10.8778 4.8159 11.0309 4.96898C11.184 5.12206 11.3231 5.28383 11.4481 5.4527L12.6551 4.2458ZM5.45251 11.4483C5.28364 11.3232 5.12187 11.1842 4.9688 11.0311C4.81571 10.878 4.67662 10.7162 4.55153 10.5474L3.3448 11.754C3.096 12.0028 3.096 12.4062 3.3448 12.655C3.5936 12.9038 3.99699 12.9038 4.2458 12.655L5.45251 11.4483Z" fill="white"/>
            <path class="Toggle-icon" fill-rule="evenodd" clip-rule="evenodd" d="M4.2458 3.3448C3.997 3.096 3.59361 3.096 3.3448 3.3448C3.096 3.59361 3.096 3.997 3.3448 4.2458L4.55172 5.45271C4.67681 5.28382 4.8159 5.12203 4.96898 4.96894C5.12206 4.81586 5.28383 4.67679 5.4527 4.55171L4.2458 3.3448ZM11.4483 10.5473C11.3233 10.7162 11.1841 10.878 11.0311 11.0311C10.878 11.1841 10.7162 11.3233 10.5473 11.4483L11.7541 12.6551C12.0029 12.9039 12.4062 12.9039 12.6551 12.6551C12.9039 12.4063 12.9039 12.0029 12.6551 11.7541L11.4483 10.5473Z" fill="white"/>
        </svg>

        <label class="Toggle">
            <input type="checkbox" name="toggle" class="Toggle__input" v-on:input="changeToggle($event.target.checked)" :checked="$store.state.theme" />
            <span class="Toggle__display" hidden></span>
        </label>

        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_49_457)">
                <path class="Toggle-icon"  d="M11.9629 8.55404C11.8839 8.36122 11.6978 8.24467 11.501 8.24442C11.5 8.24442 11.499 8.24417 11.498 8.24417C11.4947 8.24417 11.492 8.24542 11.4887 8.24567C11.4502 8.24667 11.4117 8.25292 11.3734 8.26318C11.3592 8.26693 11.3457 8.27068 11.3319 8.27543C11.3249 8.27794 11.3176 8.27894 11.3104 8.28169C10.6829 8.53954 10.0164 8.67034 9.32888 8.67034C6.45352 8.67034 4.11412 6.33094 4.11412 3.45558C4.11412 2.76806 4.24467 2.10156 4.50277 1.47406C4.50652 1.46531 4.50777 1.45606 4.51077 1.44705C4.51452 1.43605 4.51777 1.42505 4.52077 1.41354C4.53203 1.37227 4.53903 1.33076 4.53953 1.28924C4.53953 1.28749 4.54028 1.28574 4.54028 1.28374C4.54028 1.28324 4.54003 1.28249 4.54003 1.28199C4.54003 1.25198 4.53653 1.22247 4.53103 1.19346C4.52978 1.1872 4.52878 1.18095 4.52753 1.1747C4.52102 1.14594 4.51277 1.11743 4.50127 1.09042C4.49676 1.07991 4.49051 1.07091 4.48551 1.0609C4.43324 0.956615 4.34671 0.868831 4.23016 0.821062C4.09386 0.765291 3.94805 0.775295 3.82501 0.834067C3.82376 0.834568 3.82225 0.834818 3.821 0.835568C3.07347 1.14294 2.39871 1.59411 1.81548 2.17733C-0.605202 4.60102 -0.605202 8.54454 1.81573 10.9687C3.0277 12.1792 4.61931 12.7842 6.21117 12.7842C7.80304 12.7842 9.3949 12.1789 10.6071 10.9685C11.1883 10.387 11.6388 9.71449 11.9461 8.96945C11.9476 8.9667 11.9481 8.96345 11.9494 8.96045C11.9636 8.93069 11.9751 8.89967 11.9834 8.86741C11.9839 8.86491 11.9849 8.86266 11.9856 8.86016C12.0094 8.76162 12.0044 8.65508 11.9629 8.55404ZM9.90035 10.2612C7.86631 12.2922 4.55704 12.2922 2.5235 10.2612C0.492221 8.22742 0.492221 4.91814 2.52325 2.88461C2.74034 2.66752 2.97493 2.4762 3.22077 2.30388C3.15025 2.68103 3.11373 3.06543 3.11373 3.45583C3.11373 6.8829 5.90181 9.67097 9.32888 9.67097C9.71903 9.67097 10.1037 9.63446 10.4808 9.56393C10.3083 9.80952 10.1174 10.0441 9.90035 10.2612Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_49_457">
                    <rect class="Toggle-icon" width="12" height="12" fill="white" transform="translate(0 0.78418)"/>
                </clipPath>
            </defs>
        </svg>


        <div class="menuButton" :class="{ 'menuButton-is-active': isOpen}" v-on:click="openMobileNav">
            <span class="firstMenuBut" :class="{ 'firstMenuBut-is-active': isOpen}"></span>
            <span class="secondMenuBut" :class="{ 'secondMenuBut-is-active': isOpen}"></span>
        </div>

        <Transition>
            <div v-if="isOpen" class="mobileNav">
                <nav>
                    <a href="https://faircrypto.org/xencryptolp.pdf" class="navButton" target="_blank" v-on:click="openMobileNav"><span>{{ $store.state.translation[$store.state.lang].roadmap }}</span></a>
                    <a href="https://xen.network/mainnet/mint" class="navButton" target="_blank" v-on:click="openMobileNav"><span>{{ $store.state.translation[$store.state.lang].mint }}</span></a>
                    <a href="https://xen.network/mainnet/stake" class="navButton" target="_blank" v-on:click="openMobileNav"><span>{{ $store.state.translation[$store.state.lang].stake }}</span></a>
                    <a href="https://xen.network/mainnet/xenft" class="navButton" target="_blank" v-on:click="openMobileNav"><span>{{ $store.state.translation[$store.state.lang].xenft }}</span></a>
                    <v-select
                        :options='options'
                        :selected='lang'

                        @select="openMobileNav"
                    />
                </nav>
            </div>
        </Transition>
        
    </div>
</template>

<script>
import vSelect from './v-select.vue'

export default {
    name: 'v-toggle',

    components: {
        vSelect
    },

    data() {
        return {
            isOpen: false,
            options: [
                { name: 'en', value: 'En'},
                { name: 'ru', value: 'Ru'}
            ],
            lang: 'ru'
        }
    },

    methods: {
        changeToggle() {
            this.$store.dispatch('setTheme', !this.$store.state.theme)

            if (this.$store.state.theme) {
                document.querySelector('html').classList.add('theme-dark')
                document.querySelector('html').classList.remove('theme-light')
            } else {
                document.querySelector('html').classList.add('theme-light')
                document.querySelector('html').classList.remove('theme-dark')
            }

            this.setCookie('theme', this.$store.state.theme? 'dark' : 'light' )
        },

        openMobileNav() {
            this.isOpen = !this.isOpen
            if (this.isOpen) {
                document.body.style.overflowY = 'hidden'
            } else {
                document.body.style.overflowY = 'auto'
            }
        },

        // cookie
        getCookie(name) {
            let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        },

        setCookie(name, value, options = {}) {
            options = {
                path: '/',
                ...options
            }
            if (options.expires instanceof Date) {
                options.expires = options.expires.toUTCString()
            }
            let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)
            for (let optionKey in options) {
                updatedCookie += "; " + optionKey
                let optionValue = options[optionKey]
                if (optionValue !== true) {
                    updatedCookie += "=" + optionValue
                }
            }
            document.cookie = updatedCookie
        },

    },

    created() {
        if (this.getCookie('theme') == 'dark') {
            this.$store.dispatch('setTheme', true)
        }
        this.setCookie('theme', this.$store.state.theme? 'dark' : 'light' )
    },

    mounted() {
        if (this.getCookie('theme') == 'dark') {
            document.querySelector('html').classList.add('theme-dark')
            document.querySelector('html').classList.remove('theme-light')
        } else {
            document.querySelector('html').classList.add('theme-light')
            document.querySelector('html').classList.remove('theme-dark')
        }
    }
}
</script>

<style scoped>

.Toggle-markup {
    min-width: 103px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.Toggle-icon {
    fill: var(--color-svg);
    transition: all .25s;
}

.Toggle-icon-stroke {
    stroke: var(--color-svg);
    transition: all .25s;
}

.Toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  cursor: pointer;

  width: 54px;
  height: 25px;
  border: solid 0;

  border-radius: 100vw;
}

button.Toggle {
  border: 0;
  padding: 0;
  background-color: transparent;
  font: inherit;
}

.Toggle__input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 100vw;
}

.Toggle__display {
  --offset: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: content-box;
  width: 54px;
  height: 25px;
  position: relative;
  border-radius: 100vw;
  background-color: var(--toggle-bg-color);

}

.Toggle__display::before {
  content: "";
  z-index: 2;
  position: absolute;
  left: var(--toggle-position);
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: all .2s;
}

.menuButton {
    margin-left: 40px;
    display: none;
    position: relative;
    height: 16px;
    width: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    transition: all .2s;
    z-index: 1110;
    cursor: pointer;
}

.firstMenuBut, 
.secondMenuBut {
    position: absolute;

    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: var(--color-svg);
    transition: all .2s;
}

.firstMenuBut {
    top: 28%;
}

.secondMenuBut {
    bottom: 28%;
    width: 70%;
}

.menuButton-is-active {
    height: 16px;
}

.firstMenuBut-is-active {
    transform: rotate(-45deg);
    top: calc(50% - 1px);
}

.secondMenuBut-is-active {
    width: 100%;
    bottom: calc(50%);
    transform: rotate(45deg);
}

.mobileNav {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    background: var(--mobile-bg-color);
}

.mobileNav nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 23px;
}

.navButton {
    color: var(--main-color);
    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
    transition: all .2s;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1440px) {
    .menuButton {
        display: flex;
    }
}

@media screen and (max-width: 760px) {

.menuButton {
    margin-left: 20px;
}
}

</style>
  