<template>
    <div class="nav">
        <div class="navLogo">
            XEN
        </div>

        <nav class="navButtonPanel">
            <a href="https://faircrypto.org/xencryptolp.pdf" class="navButton" target="_blank"><span>{{ $store.state.translation[$store.state.lang].roadmap }}</span></a>
            <a href="https://xen.network/mainnet/mint" class="navButton" target="_blank"><span>{{ $store.state.translation[$store.state.lang].mint }}</span></a>
            <a href="https://xen.network/mainnet/stake" class="navButton" target="_blank"><span>{{ $store.state.translation[$store.state.lang].stake }}</span></a>
            <a href="https://xen.network/mainnet/xenft" class="navButton" target="_blank"><span>{{ $store.state.translation[$store.state.lang].xenft }}</span></a>
            <v-select
                :options='options'
                :selected='lang'
            />
        </nav>

        <v-toggle/>
    </div>
</template>

<script>
import vToggle from './v-toggle.vue'
import vSelect from './v-select.vue'

export default {
    name:'headerNav',

    components: {
        vToggle,
        vSelect
    },

    data() {
        return {
            options: [
                { name: 'en', value: 'en'},
                { name: 'ru', value: 'ru'}
            ],
            lang: 'ru'
        }
    },

}
</script>

<style scoped>
 
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navLogo {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    transition: all .2s;
}

.navButtonPanel {
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
}

.navButton {
    color: var(--main-color);
    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
    transition: all .2s;
}

.navButton:hover {
    opacity: .5;
}

@media screen and (max-width: 1440px) {
    .navButtonPanel {
        display: none;
    }
}

@media screen and (max-width: 760px) {
    .navLogo {
        font-size: 24px;
        line-height: 29px;
    }
}

</style>