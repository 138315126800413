import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'en',
    theme: false,
    tokenprice: '...',
    changeprice: '...',

    translation: {
      'ru': {
        roadmap: 'Дорожная карта',
        mint: 'Mint',
        stake: 'Stake',
        xenft: 'XENft',
        firsttitle1: 'Самая быстрорастущая криптовалюта',
        firsttitle2: 'К сегодняшнему дню XEN торгуется на 17 биржах и торговый объём достигает 1,4 млн. долларов.',
        pricetoken: 'ЦЕНА ТОКЕНА',
        pricetokenlogo: 'XEN',
        wherebuy: 'Где купить XEN Crypto',
        wherebuydis: 'Вы можете купить XEN на биржах или прямо из кошельков.',
        fourth1: 'Токен XEN занимает лидирующие позиции в мире криптовалют как самый быстро растущий токен.',
        fourth2: 'Цена токена XEN низменно будет расти в течение нескольких лет',
        footer: 'Данный сайт не является публичной офертой, не предоставляет никаких финансовых услуг и несет только информационный характер',
        rights: 'all rights reserved'
      },
      'en': {
        roadmap: 'Roadmap',
        mint: 'Mint',
        stake: 'Stake',
        xenft: 'XENft',
        firsttitle1: 'The fastest growing cryptocurrency',
        firsttitle2: 'To date, XEN is traded on 17 exchanges and the trading volume reaches $ 1.4 million.',
        pricetoken: 'PRICE OF THE',
        pricetokenlogo: 'XEN TOKEN',
        wherebuy: 'Where to Buy XEN Crypto',
        wherebuydis: 'You can buy XEN on exchanges or directly from wallets.',
        fourth1: 'The XEN token occupies a leading position in the world of cryptocurrencies as the fastest growing token.',
        fourth2: 'The price of the XEN token will grow low for several years',
        footer: 'This site is not a public offer, does not provide any financial services and is for informational purposes only',
        rights: 'all rights reserved'
      }
    }
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    setTokenprice(state, tokenprice) {
      state.tokenprice = tokenprice
    },
    setChangeprice(state, changeprice) {
      state.changeprice = changeprice
    },
  },
  actions: {
    setLang({commit}, lang) {
      commit('setLang', lang)
    },
    setTheme({commit}, theme) {
      commit('setTheme', theme)
    },
    setTokenprice({commit}, tokenprice) {
      commit('setTokenprice', tokenprice)
    },
    setChangeprice({commit}, changeprice) {
      commit('setChangeprice', changeprice)
    }
  }
})