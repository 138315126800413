<template>
  <div class="main" style="position: relative">
    <div class="firstCircle"></div>
    <div class="secondCircle"></div>
    <div class="thirdCircle"></div>
    <div class="fourthCircle"></div>

    <img src="./assets/coin1.png" class="coin1">
    <img src="./assets/coin2.png" class="coin2">

    <headerNav class="nav"/>
    <router-view/>
  </div>
  
</template>

<script>
import headerNav from './components/headerNav.vue'

export default {
  components: {
    headerNav
  },

  methods: {
    async request() {
      let response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=xen-crypto&vs_currencies=usd&include_24hr_change=true&precision=18', {
        method: 'get',
        headers: {
          'content-type': 'application/json',
        }
      })
      if (response.ok) {
        const blocks = await response.json()
        this.$store.dispatch('setTokenprice', Object.values(blocks)[0].usd)
        if (Object.values(blocks)[0]['usd_24h_change'] > 0) {
          document.querySelector('html').classList.add('token-up')
          document.querySelector('html').classList.remove('token-down')
        } else {
          document.querySelector('html').classList.add('token-down')
          document.querySelector('html').classList.remove('token-up')
        }
        const changeprice = Object.values(blocks)[0]['usd_24h_change'].toFixed(2)
        this.$store.dispatch('setChangeprice', changeprice)
      } else {
        console.log("Error HTTP: " + response.status)
      }
    }
  },

  async mounted() {
    for (let lang in window.navigator.languages) {
      if (Object.keys(this.$store.state.translation).includes(window.navigator.languages[lang].substr(0,2).toLowerCase())) {
        this.$store.dispatch('setLang', window.navigator.languages[lang].substr(0,2).toLowerCase())
        break
      }
    }

    this.request()
    setInterval(this.request, 60000)
  }
}
</script>

<style>
/* @import url('https://fonts.google.com/specimen/Inter?query=inter'); */

*, ::before, ::after {
  box-sizing: border-box;
}

.theme-light {
  --main-bg-color: #E2E8EC;
  --sub-bg-color: #E8EEF2;
  --toggle-bg-color: #000000;
  --mobile-bg-color: rgba(255, 255, 255, .7);

  --main-color: #000000;
  --sub-first-color: #989898;
  --sub-second-color: #797979;

  --color-svg: #000000;
  --color-sub-svg: #000000;

  --border-color: #FFFFFF;
  --border-footer-color: #000000;

  --toggle-position: 6px;

  --first-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(247, 246, 67, 0.07) 0%, rgba(253, 253, 59, 0) 100%);
  --second-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(199, 20, 181, 0.09) 0%, rgba(191, 26, 175, 0) 100%);
  --third-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(20, 134, 199, 0.1) 0%, rgba(26, 132, 191, 0) 100%);
  --fourth-circle-coloe: radial-gradient(50% 50% at 50% 50%, rgba(255, 38, 38, 0.16) 0%, rgba(191, 26, 35, 0) 100%);

  --coin-opacity: 0;
}

.theme-dark {
  --main-bg-color: #141414;
  --sub-bg-color: #1B1B1B;
  --toggle-bg-color: #383838;
  --mobile-bg-color: rgba(0, 0, 0, .7);

  --main-color: #ffffff;
  --sub-first-color: #989898;
  --sub-second-color: #797979;

  --color-svg: #FFFFFF;
  --color-sub-svg: #FDE400;

  --border-color: #2F2F2F;
  --border-footer-color: #444444;

  --toggle-position: calc(100% - 21px);

  --first-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(247, 246, 67, 0.22) 0%, rgba(253, 253, 59, 0) 100%);
  --second-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(199, 20, 181, 0.47) 0%, rgba(191, 26, 175, 0) 100%);
  --third-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(20, 134, 199, 0.37) 0%, rgba(26, 132, 191, 0) 100%);
  --fourth-circle-color: radial-gradient(50% 50% at 50% 50%, rgba(255, 38, 38, 0.37) 0%, rgba(191, 26, 35, 0) 100%);

  --coin-opacity: 1;
}

.token-up {
  --token-color: #039F00;
}

.token-down {
  --token-color: #db0000;
}

body {
  background: var(--main-bg-color);
  color: var(--main-color);
  transition: background .2s;
  margin: 0px;
}

#app {
  overflow-x: hidden;
}

.main {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;

  max-width: 1320px;
  margin: 0 auto;
  padding: 26px;

  box-sizing: content-box;
}

.nav {
  margin-top: 14px;
}

/* circle */
.firstCircle {
  position: absolute;
  z-index: -1;
  width: 748px;
  height: 748px;
  left: 85%;
  top: -8%;
  background: var(--first-circle-color);
}

.secondCircle {
  position: absolute;
  z-index: -1;
  width: 748px;
  height: 748px;
  left: -48%;
  top: 15%;
  background: var(--second-circle-color);
}

.thirdCircle {
  position: absolute;
  z-index: -1;
  width: 748px;
  height: 748px;
  left: 90%;
  top: 33%;
  background: var(--third-circle-color);
}

.fourthCircle {
  position: absolute;
  z-index: -1;
  width: 748px;
  height: 748px;
  left: -48%;
  top: 53%;
  background: var(--fourth-circle-color);
}

.coin1 {
  opacity: var(--coin-opacity);
  position: absolute;
  top: 4.7%;
  left: -35%;
  transform: rotate(-24.5deg);
  transition: opacity .2s;
}

.coin2 {
  opacity: var(--coin-opacity);
  position: absolute;
  top: 20%;
  left: 107%;
  transform: rotate(-24.5deg);
  transition: opacity .2s;
}

@media screen and (max-width: 1440px) {
  .main {
    max-width: 768px;
  }

  .nav {
    margin-top: 10px;
  }

  .coin1, .coin2 {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .nav {
    margin-top: 0px;
  }

  .firstCircle {
    left: 65%;
  }

  .secondCircle {
    left: -57%;
    top: 12%;

  }

  .thirdCircle {
    left: 75%;
  }

  .fourthCircle {
    left: -55%;
    top: 63%;
  }
}

@media screen and (max-width: 760px) {
  .main {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .firstCircle {
    left: 50%;
  }

  .secondCircle {
    left: -150%;
    top: 16%;

  }

  .thirdCircle {
    left: 75%;
  }

  .fourthCircle {
    left: -55%;
    top: 63%;
  }
}

</style>
