<template>
    <div class='v-select-lang'>
        <div class='nav-button-lang' @click="openOptions" :class='{activeLang: areOptionsVisible}'>

            <div>{{ $store.state.lang.toUpperCase() }}</div>
            
            <svg width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0.5L4.30476 3.33265C4.41711 3.42895 4.58289 3.42895 4.69524 3.33265L8 0.5" stroke="white" stroke-linecap="round"/>
            </svg>

        </div>

        <transition name="component-fade">
            <div class='options' v-if='areOptionsVisible'>
                <p v-for='option in options' :key='option.value' @click='selectOption(option.value)' class='noClick nav-button-lang'>
                    {{option.value.toUpperCase()}}
                </p>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'v-select-lang',
    props: {
        options: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            areOptionsVisible: false,
        }
    },
    methods: {
        closeWindow(event) {
            let close = true
            for (let i in event.composedPath()) {
                if (event.composedPath()[i].className == 'v-select-lang') {
                    close = false
                }
            }
            if (close) {
                this.areOptionsVisible = false
            }
        },
        selectOption(option) {
            this.$store.dispatch('setLang', option.toLowerCase())
            this.$emit('select')
            this.areOptionsVisible = false
        },
        openOptions() {
            this.areOptionsVisible = !this.areOptionsVisible
        },
    },

    mounted() {
        window.lang = "ru"
        document.addEventListener('click', this.closeWindow, true)
        document.addEventListener('scroll', this.closeWindow, true)
    },

    beforeUnmount() {
        document.removeEventListener('click', this.closeWindow, true)
        document.removeEventListener('scroll', this.closeWindow, true)
    }
}
</script>

<style scoped>
    .v-select-lang {
        position: relative;
        width: min-content;
        cursor: pointer;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;  
    }

    .v-select-lang p {
        margin: 0;
    }

    .options {
        position: absolute;
        top: 40px;
        left: 0;
        border-radius: 12px;
        background: var(--sub-bg-color);
        width: 100%;
    }

    .activeLang {
        opacity: .5;
    }

    .activeLang svg {
        transform: scale(-1);
    }

    .nav-button-lang {
        padding: 8px 16px;
        border-radius: 4px;
        transition: opacity .2s;
        max-width: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 4px;
    }

    .nav-button-lang div {
        transition: color .2s;
    }

    .nav-button-lang:hover {
        opacity: .5;
    }

    .nav-button-lang svg {
        transition: all .5s;
    }

    .nav-button-lang svg path {
        transition: all .2s;
        stroke: var(--main-color);
    }

    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .3s ease;
    }
    .component-fade-enter, .component-fade-leave-to {
        opacity: 0;
    }
    </style>